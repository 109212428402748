.App {
  text-align: center;
}

.PublicRoute {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0;
}

.errorMsg {
  color:red!important;
}